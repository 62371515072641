import React, { useReducer, createContext, useEffect } from 'react';
import { fetchEntries, filterByStatus, filterTodayTodos, filterTodos, filterDayTodos } from '../services/TodoService';

export const TodoContext = createContext();

const initialState = {
    stats: {},
    tags: {},
    calendar: null,
    displayedPages: 1,
    pageSize: 10,
    isLoading: true,
    view: "list",
    modal: null,
    alert: null,
    lastFilterApplied: null,
    selectedTodos: [],
    searchParams: {
        tag: "",
        status: "All",
    },
    list: [],
    selectedTodo: null,
    originalList: [],
    heading: "",
    recurringList: [],
    selectedRecurring: null,
}

const reducer = (state, action) => {
    return actionDispatchMap[action.type](state, action);
}

export const TodoContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, { ...initialState, user: props.user });
    useEffect(() => {
        fetchEntries(props.user.access_token, {}).then(res => {
            dispatch({ type: "load", payload: res })
        })
    }, [props.user.access_token, dispatch])
    return <TodoContext.Provider value={[state, dispatch]}>{props.children}</TodoContext.Provider>;
}
const computePages = (list, pageNumber) => {
    const possiblePages = Math.ceil(list.size / 10)
    if (pageNumber > possiblePages) {
        return possiblePages;
    }
    return pageNumber;
}
const loadTodos = (initialState, action) => {
    const list = initialState.searchParams.tag == null ||  initialState.searchParams.tag === "" ?
    filterTodayTodos(action.payload) :
    filterTodos(action.payload, initialState.searchParams);

    const attributes = initialState.searchParams.tag == null ||  initialState.searchParams.tag === "" ? todayAttributes : searchResultAttributes

    return { ...initialState, originalList: action.payload, list: list, ...attributes, ...resetInterfaceAttributes }
}
const incompleteTodos = (state, action) => {
    const alertMessage = action.payload && action.payload.alert ? action.payload.alert : null;
    performUpdation(state, action);
    const newList = filterByStatus(state.originalList, "incomplete");
    const pagination = action.type === "incomplete" ? 1 : computePages(newList, state.displayedPages)
    return { ...state, displayedPages: pagination, list: newList, alert: alertMessage, ...incompleteAttributes, ...resetInterfaceAttributes }
}
const completeTodos = (state, action) => {
    const alertMessage = action.payload && action.payload.alert ? action.payload.alert : null;
    performUpdation(state, action);
    const newList = filterByStatus(state.originalList, "complete");
    const pagination = action.type === "complete" ? 1 : computePages(newList, state.displayedPages)
    return { ...state, displayedPages: pagination, list: newList, alert: alertMessage, ...CompleteAttributes, ...resetInterfaceAttributes }
}
const searchTodosByParams = (state, action) => {
    const alertMessage = action.payload && action.payload.alert ? action.payload.alert : null;
    performUpdation(state, action);
    const newList = filterTodos(state.originalList, state.searchParams);
    const pagination = action.type === "searchResult" ? 1 : computePages(newList, state.displayedPages)
    return { ...state, displayedPages: pagination, list: newList, alert: alertMessage, ...searchResultAttributes, ...resetInterfaceAttributes }
}
const addTodo = (state, action) => ({ ...state, view: "add" })

const todayTodos = (state, action) => {
    const alertMessage = action.payload && action.payload.alert ? action.payload.alert : null;
    performUpdation(state, action)
    const newList = filterTodayTodos(state.originalList)
    const pagination = action.type === "today" ? 1 : computePages(newList, state.displayedPages)
    return { ...state, displayedPages: pagination, list: newList, alert: alertMessage, ...todayAttributes, ...resetInterfaceAttributes}

}

const performUpdation = (state, action) => {
    if (state.view === "edit" && action.payload.response) {
        const todo = state.originalList.find(todo => todo.id === state.selectedTodo.id)
        if (todo) {
            todo.description = action.payload.response.description
            todo.due_date = action.payload.response.due_date
            todo.priority = action.payload.response.priority
            todo.tags = action.payload.response.tags

        }
    }

}
const increamentPage = (state, action) => {
    return { ...state, displayedPages: state.displayedPages + 1 }
}
const decreamentPage = (state, action) => ({ ...state, displayedPages: state.displayedPages - 1 })
const toggleSelect = (state, action) => {
    const index = state.selectedTodos.indexOf(action.payload)
    if (index > -1) {
        const elements = state.selectedTodos;
        elements.splice(index, 1)
        return { ...state, selectedTodos: elements }
    } else {
        return { ...state, selectedTodos: [...state.selectedTodos, action.payload] }
    }
}
const decreamentDate = (state, action) => {
    if (!state.calendar.canGoBack) {
        return state;
    }
    const date = modifyDate(state.calendar.date, -1)
    if (date.getDate() === new Date().getDate()) {
        return todayTodos(state, action)
    }
    return dayTodos({ ...state, calendar: {...state.calendar, date: date } }, action)
}
const dayTodos = (state, action) => {
    const alertMessage = action.payload && action.payload.alert ? action.payload.alert : null;
    performUpdation(state, action);
    return { ...state, list: filterDayTodos(state.originalList, state.calendar.date), heading: state.calendar.date.toDateString() + " todos", alert: alertMessage, ...dayAttributes, ...resetInterfaceAttributes }
}

const modifyDate = (date, diff) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + diff)
    return newDate;
}

const deletedRecurringTodo = (state, action) => {
    const newList = state.recurringList.filter((todo) => todo.id !== action.payload.response.id)
    return { ...state, view: "list", selectedRecurring: null, alert: action.payload.alert, recurringList: newList }
}
const resetInterfaceAttributes = { modal: null, selectedTodo: null, selectedTodos: [], isLoading: false }
const incompleteAttributes = { heading: "Incomplete todos", calendar: null, view: "list", lastFilterApplied: incompleteTodos }
const CompleteAttributes = { heading: "Completed todos", calendar: null, view: "list", lastFilterApplied: completeTodos }
const todayAttributes = { heading: "Today todos", view: "list", lastFilterApplied: todayTodos, calendar: { date: new Date(), canGoBack: false }, searchParams: {tag: "", status: "All"} }
const dayAttributes = { view: "list", lastFilterApplied: dayTodos }
const searchResultAttributes = { heading: "Search result", view: "list", lastFilterApplied: searchTodosByParams, calendar: null }

const actionDispatchMap = {
    incomplete: incompleteTodos,
    complete: completeTodos,
    today: todayTodos,
    searchResult: (state, action) => searchTodosByParams({ ...state, searchParams: action.payload }, action),
    add: addTodo,
    bulkAdd: (state, action) => ({ ...state, view: "bulkAdd" }),
    load: loadTodos,
    search: (state, action) => ({ ...state, view: "search" }),
    saved: (state, action) => state.lastFilterApplied({ ...state, originalList: [...state.originalList, action.payload.response] }, action),
    bulkSaved: (state, action) => state.lastFilterApplied({ ...state, originalList: [...state.originalList, ...action.payload.todos] }, action),
    increamentPage: increamentPage,
    decreamentPage: decreamentPage,
    toggleSelect: toggleSelect,
    edit: (state, action) => ({ ...state, view: "edit", selectedTodo: action.payload }),
    cancel: (state, action) => ({ ...state, view: "list", selectedTodo: null }),
    updated: (state, action) => state.lastFilterApplied(state, action),
    selectAll: (state, action) => ({ ...state, selectedTodos: state.list.map((t, i) => t.id) }),
    clearSelection: (state, action) => ({ ...state, selectedTodos: [] }),
    setStats: (state, action) => ({ ...state, stats: action.payload }),
    setTags: (state, action) => ({ ...state, tags: action.payload }),
    changeDate: (state, action) => ({ ...state, modal: "changeDate" }),
    closeAlert: (state, action) => ({ ...state, alert: null }),
    increamentDate: (state, action) => dayTodos({ ...state, calendar: { date: modifyDate(state.calendar.date, 1), canGoBack: true } }, action),
    decreamentDate: decreamentDate,
    loadRecurring: (state, action) => ({ ...state, recurringList: action.payload.response, ...resetInterfaceAttributes }),
    editRecurring: (state, action) => ({ ...state, view: "editRecurring", selectedRecurringTodo: action.payload.selectedTodo }),
    updatedRecurringTodo: (state, action) => ({ ...state, view: "list", selectedRecurring: null, alert: action.payload.alert }),
    deletedRecurringTodo: deletedRecurringTodo,
    closeModal: (state, action) => ({ ...state, modal: null }),
}
