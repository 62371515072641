import { postFetchCall, makeFetchCall} from './BaseService';
import config from '../config';

export const saveBacklog = async (accessToken, data) => {
    console.log("Inside saveBacklog function.");
    const url = config.Todo.API_HOST + "/backlog";
    const response = await postFetchCall(url, "POST", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}

export const fetchBacklog= async (accessToken) => {
    const url = config.Todo.API_HOST + "/backlog";
    const response = await makeFetchCall(url, accessToken);
    const responseJson = await response.json();
    console.log("Fetched backlog.");
    return responseJson;
}
export const updateBacklog = async (accessToken, data) => {
    console.log("Inside updateBacklog function.");
    const url = config.Todo.API_HOST + "/backlog";
    const response = await postFetchCall(url, "PUT", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}
export const deleteBacklog = async (accessToken, data) => {
    console.log("Inside deleteBacklog function.");
    const url = config.Todo.API_HOST + "/backlog";
    const response = await postFetchCall(url, "DELETE", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}