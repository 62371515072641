import React, { useContext, useEffect } from 'react';
import { TodoContext } from '../context/TodoContext';
import {  Button, Row, Col, ListGroup, Container, Navbar } from 'react-bootstrap';
import RecurringTodoForm from '../components/RecurringTodoForm';
import EditRecurringTodoForm from '../components/EditRecurringTodoForm';
import { AlertMessage } from './ListTodos';
import { useRecurringTodoAPI } from '../hooks/UseRecurringTodoAPI';
import MdCreate from 'react-ionicons/lib/MdCreate';
import MdRemove from 'react-ionicons/lib/MdRemove';

export function RecurringTodos(props) {
    return (
        <Container fluid>
            <Panel />
        </Container>
    )
}

function Panel(props) {
    const [state,] = useContext(TodoContext)
    return (
        <>
            <ActionBar />
            {state.view === "add" && <RecurringTodoForm />}
            {state.view === "editRecurring" && <EditRecurringTodoForm />}
            {state.view === "list" && <List />}
        </>

    )
}
function ActionBar(props) {
    const [, dispatch] = useContext(TodoContext);
    return (
        <Navbar bg="info" variant="light">
            <h2 className="navbar-text h4">Recurring todos</h2>
            <Navbar.Text className="ml-auto" role="link" onClick={() => dispatch({ type: "add" })}>
                Add
            </Navbar.Text>
        </Navbar>
    )
}
function List(props) {
    return (
        [<AlertMessage key={2} />,
        <RecurringList key={3} />]
    )
}
function RecurringList(props) {
    const [state, dispatch, service] = useRecurringTodoAPI()
    const loadRecurring = service.loadRecurring;
    useEffect(() => { loadRecurring() }, [loadRecurring])
    return (
        <ListGroup as="ul">
            {state.recurringList.map((todo, i) => {
                return (
                    <ListGroup.Item as="li" key={i}>
                        <Row>
                            <Col xs={12} md={3}>
                                {computeRepetition(todo)}
                            </Col>
                            <Col xs={12} md={6}>
                                {todo.description}
                            </Col>
                            <Col xs={12} md={3}>
                                <Button size="sm" onClick={() => dispatch({type: "editRecurring", payload: {selectedTodo: todo}})}>
                                <MdCreate color="white" fontSize="24" /><span className="sr-only">Edit</span>
                                </Button>
                                <Button size="sm" onClick={() => service.handleDelete(todo)} variant="danger">
                                <MdRemove color="white" fontSize="24" /><span className="sr-only">Delete</span>
                                </Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    );
}
const computeRepetition = (todo) => {
    if (todo.day === 0 && todo.month === 0) {
        return "Repeats daily"
    }else if(todo.day > 0 && todo.month === 0) {
        return "Repeats monthly on " + formatDay(todo.day)
    }
}
const formatDay = (day) => {
    if ([1,21,31].includes(day)) {
        return day + "st"
    }
    if (day === 2) {
        return "2nd"
    }
    if (day === 3) {
        return "3rd"
    }
    return day + "th"
}