import React from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

export function AutoCompleteTag(props) {
    const inputProps = {
        ref: props.searchRef,
        onChange: props.onChange,
        onInputChange: props.onInputChange,
        options: props.options,
        defaultInputValue: props.defaultInputValue ? props.defaultInputValue : "",
        as: props.as,
        xs: props.xs,
        md: props.md,
    }
    return (
        <Form.Group controlId={props.controlId}>
            <Form.Label>Tag:</Form.Label>
            {/* <Form.Control type="text" placeholder="Enter tag" {...inputProps} /> */}
            <Typeahead placeholder="Enter tag" {...inputProps} id={props.controlId} />
        </Form.Group>
    )
}

export function TForm(props) {
    return (
        <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="col-xs-12 col-md-6" >
                <h2 className="h4 strong">{props.formHeading}</h2>
                <Form>
                    {props.children}
                </Form>
            </div>
        </div>
    )
}

export function DescriptionInput(props) {
    return (
        <Form.Group controlId={props.controlId}>
            <Form.Label>Description:</Form.Label>
            <Form.Control type="text" placeholder="Enter description" onChange={props.onChange} ref={props.descriptionRef} value={props.value} />
        </Form.Group>
    )
}

export function PriorityInput(props) {
    return (
        <Form.Group controlId={props.controlId}>
            <Form.Label>Priority:</Form.Label>
            <Form.Control as="select" onChange={props.onChange} value={props.value}>
                <option value={1} defaultValue>Low</option>
                <option value={2}>Medium</option>
                <option value={3}>High</option>
            </Form.Control>
        </Form.Group>

    )
}

export function TFormActions(props) {
    return (
        <Form.Group>
            <Button variant="primary" type="button" className="col-xs-6 col-md-6" onClick={props.onSubmit} style={{ height: 65 }}>
                {props.submitCaption}
            </Button>
            <Button variant="secondary" type="button" className="col-xs-6 col-md-6" onClick={props.onCancel} style={{ height: 65 }}>
                Cancel
        </Button>
        </Form.Group>

    )
}

export function AlertMessage(props) {
    if (props.alert) {
        setTimeout(props.onClose, 10000)
        return (
            <Alert variant={props.alert.type}>
                {props.alert.message}
            </Alert>
        )
    } else {
        return null;
    }
}
