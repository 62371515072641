import {postFetchCall, makeFetchCall} from './BaseService';
import config from '../config';

export const fetchStats = async(accessToken) => {
    const url = config.Todo.API_HOST + "/stats";
    const response = await makeFetchCall(url, accessToken);
    const responseJson = await response.json();
    console.log("Fetched entries.");
    return responseJson;
}
export const fetchTags = async (accessToken) => {
    const url = config.Todo.API_HOST + "/tags";
    const response = await makeFetchCall(url, accessToken);
    const responseJson = await response.json();
    console.log("Fetched tags.");
    return responseJson
}
export const updateTodo = async (accessToken, data) => {
    console.log("Inside updateEntry function.");
    const url = config.Todo.API_HOST + "/update";
    const response = await postFetchCall(url, "POST", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}
export const updateBulkTodos = async (accessToken, data) => {
    console.log("Inside updateBulkTodos function.");
    const url = config.Todo.API_HOST + "/bulk_update";
    const response = await postFetchCall(url, "POST", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}
export const addBulkTodos = async (accessToken, data) => {
    console.log("Inside addBulkTodos function.");
    const url = config.Todo.API_HOST + "/bulk_add";
    const response = await postFetchCall(url, "POST", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}
export const saveTodo = async (accessToken, data) => {
    const url = config.Todo.API_HOST + "/save";
    const response = await postFetchCall(url, "POST", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}

export const fetchEntries = async (accessToken, filter) => {
    const url = config.Todo.API_HOST + "/all";
    console.log(url);
    const response = await makeFetchCall(url, accessToken);
    const responseJson = await response.json();
    console.log("Fetched entrys.");
    return responseJson;
}
export const filterTodos = (todos, filterParams) => {
    const filter =(todo) => {
        if (todo.tags === null || todo.tags === "") {
            return false;
        }
        if (filterParams.status === "All" && filterParams.tag === "") {
            return true;
        }
        if (todo.tags.includes(filterParams.tag) && filterParams.status === "All") {
            return true;
        }
        if (todo.tags.includes(filterParams.tag) && todo.status === filterParams.status) {
            return true;
        }
        return false;
    }
    var list = todos.filter(filter);
    return list.sort(compare);
}
export const filterByStatus = (todos, status) => {
    const filter = (todo) => (todo.status === status)
    var list = todos.filter(filter);
    return list.sort(compare);
}
export const filterTodayTodos = (todos) => {
    const filter = (todo) => isDue(todo.due_date)  && todo.status === "incomplete"
    var list = todos.filter(filter);
    return list.sort(compare);
}

function isDue(dueDate) {
    let strDate = new Date(Date.parse(dueDate)).toDateString();
    let date = new Date(Date.parse(strDate));
    let strTodayDate = new Date().toDateString();
    let todayDate = new Date(Date.parse(strTodayDate));
    return date.getTime() <= todayDate.getTime();
}

export const filterDayTodos = (todos, date) => {
    const filter = (todo) => isDueDate(todo.due_date, date)  && todo.status === "incomplete"
    var list = todos.filter(filter);
    return list.sort(compare);
}
function isDueDate(dueDate, date) {
    let strDueDate = new Date(Date.parse(dueDate)).toDateString();
    let localDueDate = new Date(Date.parse(strDueDate));
    let strDate = new Date(Date.parse(date)).toDateString();
    let localDate = new Date(Date.parse(strDate));
    return localDueDate.getTime() === localDate.getTime();
}
function compare( a, b ) {
    var diff = compareDate(a.due_date, b.due_date);
    if (diff !== 0) {
        return diff;
    }
    if(a.priority < b.priority)
            return 1;
            
            if(a.priority >b.priority)
        return -1;
    
    return 0;
}
function compareDate(firstDate, secondDate){
    var date1 = new Date(Date.parse(firstDate));
    var date2 = new Date(Date.parse(secondDate));
    if (date1.getTime() < date2.getTime()) {
        return  -1;
    }
        if (date1.getTime() > date2.getTime()) {
            return 1;
        }
        return 0;
}