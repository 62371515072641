import React, { useContext, useState } from 'react';
import { AutoCompleteTag, DescriptionInput, PriorityInput, TForm, TFormActions } from './TodoFormComponents';
import useInputFocus from 'appshome-react/dist/useInputFocus';
import { BacklogContext } from '../context/BacklogContext';
import { TodoContext } from '../context/TodoContext';

export function BacklogForm(props) {
    const [state, dispatch] = useContext(BacklogContext);
    const [backlog, setBacklog] = useState(state.view === "edit" ? state.selectedBacklog : {description: "", priority: 1, tags: ""})
    const descriptionRef = useInputFocus();
    
    const [todoState,] = useContext(TodoContext)
    const tags = Object.keys(todoState.tags).map((tag, i) => tag);

    const handleSubmit = () => {
        if (state.view === "add") {
            dispatch({type: "saved", payload: {backlog: backlog}})
        } else {
            dispatch({type: "update", payload: {backlog: backlog}})
        }
        
    }
    return (
        <TForm formHeading = {state.view === "edit" ? "Update backlog" : "Add backlog"}>
            <DescriptionInput controlId="backlog.description" value={backlog.description} descriptionRef={descriptionRef} onChange={(e) => setBacklog({...backlog, description: e.target.value})} />
            <AutoCompleteTag controlId = "backlog.tags" defaultInputValue={backlog.tags} options={tags} onInputChange={(t, e) => setBacklog({...backlog, tags: e.target.value})} />
            <PriorityInput controlId = "backlog.priority" value={backlog.priority} onChange={(e) => setBacklog({...backlog, priority: e.target.value})} />
            <TFormActions submitCaption= {state.view === "edit" ? "Update" : "Create"} 
            onSubmit={handleSubmit} 
            onCancel={() => dispatch({type: "cancel"})} />
        </TForm>
    )
}