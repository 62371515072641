import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { A } from 'hookrouter';

export function Sidebar(props) {
    const handleMenuClick = () => {
        if (!props.isLGBreakPoint) {
            props.toggleSidebar(false);
        }
    }
    return (
        <Navbar  className="h-100" 
        style={{backgroundColor:'#231F20', 
        backgroundImage: 'linear-gradient(30deg, #11cf4d, #055e21)'}}>
            <Nav className="h-100 flex-column">
            <SidebarItem linkHref="/dashboard" linkText="Dashboard" handleMenuClick={handleMenuClick} />
            <SidebarItem linkHref="/list" linkText="Todo list" handleMenuClick={handleMenuClick} />
            <SidebarItem linkHref="/recurring-todos" linkText="Recurring todos" handleMenuClick={handleMenuClick} />
            <SidebarItem linkHref="/backlog" linkText="Backlog" handleMenuClick={handleMenuClick} />
            </Nav>
        </Navbar>
    );
}
function SidebarItem(props) {
    return (
        <Nav.Item>
            <A 
            href={props.linkHref} 
            className="nav-link" 
            style = {{fontSize: '14px',
            color: '#fff',
            display: 'block',
            padding: '12px',
            paddingLeft: '30px',
            textDecoration: 'none',
            outline: 'none'}}
            onClick={props.handleMenuClick}>
                {props.linkText}
            </A>
        </Nav.Item>
    )
}
//       /* Div link on hover */
//       div a:hover {
//         color: #56ff38;
//         background: #fff;
//         position: relative;
//         background-color: #fff;
//         border-top-left-radius: 22px;
//         border-bottom-left-radius: 22px;
//       }