import React, { useContext, useEffect } from 'react';
import { Container, Navbar, ListGroup, Row, Col, Button } from 'react-bootstrap';
import { BacklogForm } from '../components/BacklogForm';
import { BacklogContext, BacklogContextProvider } from '../context/BacklogContext';
import { useTodoAPI } from '../hooks/UseTodoAPI';
import { AlertMessage } from '../components/TodoFormComponents';
import MdCreate from 'react-ionicons/lib/MdCreate';
import MdRemove from 'react-ionicons/lib/MdRemove';

export function Backlog(props) {
    const [state, , service] = useTodoAPI();
    const loadTags = service.loadTags;
    useEffect(() => { loadTags() }, [loadTags]);

    return (
        <BacklogContextProvider user={state.user}>
            <Panel />
        </BacklogContextProvider>
    )
}

function Panel(props) {
    const [state,] = useContext(BacklogContext);
    return (
        <Container fluid>
            <ActionBar />
            {state.view === "list" && <ListView />}
            {["add", "edit"].includes(state.view) && <BacklogForm />}
        </Container>

    )
}
function ActionBar(props) {
    const [, dispatch] = useContext(BacklogContext);
    return (
        <Navbar bg="info" variant="light">
            <h2 className="navbar-text h4">Backlog</h2>
            <Navbar.Text className="ml-auto" role="link" onClick={() => dispatch({ type: "add" })}>
                Add
            </Navbar.Text>
        </Navbar>
    )
}
function ListView(props) {
    const [state, dispatch] = useContext(BacklogContext);
    useEffect(() => { dispatch({ type: "load" }) }, [dispatch])
    return (
        [<AlertMessage key={1} alert = {state.alert} onClose = {() => dispatch({ type: "closeAlert" })} />,
        <BacklogList key={2} />]
    )
}
function BacklogList(props) {
    const [state, dispatch] = useContext(BacklogContext);
    return (
        <ListGroup as="ul">
            {state.list.map((backlog, i) => {
                return (
                    <ListGroup.Item as="li" key={i}>
                        <Row>
                            <Col xs={12} md={9}>
                                {backlog.description}
                            </Col>
                            <Col xs={12} md={3}>
                                <Button size="sm" onClick={() => dispatch({type: "edit", payload: {selectedBacklog: backlog}})}>
                                <MdCreate color="white" fontSize="24" /><span className="sr-only">Edit</span>
                                </Button>
                                <Button size="sm" onClick={() => dispatch({type: "delete", payload: {backlog: backlog}})} variant="danger">
                                <MdRemove color="white" fontSize="24" /><span className="sr-only">Delete</span>
                                </Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                )
            })}
        </ListGroup>
    );
}
