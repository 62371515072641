import React, { useEffect, useState } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import { useInputFocus } from 'appshome-react';
import { useTodoAPI } from '../hooks/UseTodoAPI';
import { AutoCompleteTag } from './TodoFormComponents';

export default function BulkTodoForm(props) {
    const [state, dispatch, service] = useTodoAPI();
    const initialTodoState = { due_date: new Date().toISOString().slice(0, 10), status: "incomplete", priority: 1 }
    const [todo, setTodo] = useState(initialTodoState);
    const descriptionRef = useInputFocus();
    const tags = Object.keys(state.tags).map((tag, i) => tag);
    const loadTags = service.loadTags;
    useEffect(() => {loadTags()}, [loadTags]);

    const handleSave = () => {
        let strTodos = todo.description.split("\n")
        const todos = strTodos.filter(strTodo => strTodo !== "").map((strTodo, i) => ({description: strTodo, due_date: todo.due_date, status: todo.status, priority: todo.priority, tags: todo.tags}))
        service.addBulk(todos);
    }
    return (
        <div className="d-flex justify-content-center align-items-center mt-5">
            <Form  className="w-100">
                <Form.Row>
                    <h2 className="h4 strong">Add multiple todo</h2>
                </Form.Row>
                <Form.Row>
                    <Form.Group controlId="todoForm.description" as={Col} xs={12}>
                        <Form.Label>Description:</Form.Label>
                        <Form.Control as="textarea" rows={9} placeholder="Enter description" onChange={(e) => setTodo({ ...todo, description: e.target.value })}  value={todo.description} ref={descriptionRef} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group controlId="todoForm.date" as={Col} xs={12} md={4}>
                        <Form.Label>Due date</Form.Label>
                        <Form.Control type="date" onChange={(e) => setTodo({ ...todo, due_date: e.target.value })} value={todo.due_date} />
                    </Form.Group>
                    <AutoCompleteTag controlId="todoForm.tags" as={Col} xs={12} md={4}
                    onInputChange={(t, e) => setTodo({ ...todo, tags: e.target.value })} options={tags} />
                    <Form.Group controlId="todoForm.priority" as={Col} xs={12} md={4}>
                        <Form.Label>Priority:</Form.Label>
                        <Form.Control as="select" onChange={(e) => setTodo({ ...todo, priority: e.target.value })} value={todo.priority}>
                            <option value={1} defaultValue>Low</option>
                            <option value={2}>Medium</option>
                            <option value={3}>High</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Button variant="primary" type="button" className="col-xs-6 col-md-6" onClick={handleSave} style={{ height: 65 }}>
                            Create
                        </Button>
                        <Button variant="secondary" type="button" className="col-xs-6 col-md-6" onClick={() => dispatch({ type: "cancel" })} style={{ height: 65 }}>
                            Cancel
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        </div>
    );
}