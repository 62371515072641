import  {useCallback} from 'react';
import { updateRecurringTodo, deleteRecurringTodo, fetchRecurringTodos } from '../services/RecurringTodoService';
import { useTodoAPI } from './UseTodoAPI';

export function useRecurringTodoAPI() {
    const [state, dispatch, service] = useTodoAPI();
    const loadRecurring = useCallback(async() => {
        fetchRecurringTodos(state.user.access_token).then(res => {
            dispatch({type: "loadRecurring", payload: {response: res}})
        }).catch(e=> alert(e))
    },[dispatch, state.user.access_token])

    const handleUpdate = useCallback(async(todo) => {
        const res = await updateRecurringTodo(state.user.access_token, todo)
        if (res) {
            dispatch({ type: "updatedRecurringTodo", payload: { response: todo, alert: { type: "success", message: "Selected todo updated." } } })
        } else {
            alert("Error! unable to update the todo.");
        }
    },[dispatch, state.user.access_token]);

    const handleDelete = useCallback(async(todo) => {
        const res = await deleteRecurringTodo(state.user.access_token, todo)
        if (res) {
            dispatch({ type: "deletedRecurringTodo", payload: { response: todo, alert: { type: "success", message: "Selected todo deleted." } } })
        } else {
            alert("Error! unable to delete the todo.");
        }
    },[dispatch, state.user.access_token]);

    return [state, dispatch, {
        loadRecurring: loadRecurring,
        handleUpdate: handleUpdate,
        handleDelete: handleDelete,
        loadTags: service.loadTags,
    }]
}