import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { updateTodo, saveTodo } from '../services/TodoService';
import { useInputFocus } from 'appshome-react';
import { AutoCompleteTag } from './TodoFormComponents';
import { useTodoAPI } from '../hooks/UseTodoAPI';

export default function TodoForm(props) {
    const [state, dispatch, service] = useTodoAPI();
    const initialTodoState = state.view === "add" ? { due_date: new Date().toISOString().slice(0, 10), status: "incomplete", priority: 1 } : state.selectedTodo;
    const [todo, setTodo] = useState(initialTodoState);
    const descriptionRef = useInputFocus();
    const tags = Object.keys(state.tags).map((tag, i) => tag);
    const loadTags = service.loadTags;
    useEffect(() => {loadTags()}, [loadTags]);

    const handleSave = () => {
        if (state.view === "add") {
            saveTodo(state.user.access_token, todo).then(res => {
                if (typeof res === "number") {
                    todo['id'] = res
                    dispatch({ type: "saved", payload: {response: todo, alert: {type: "success", message: "Todo created."}} })
                } else {
                    alert("Error! unable to save the todo.");
                }
            })
        } else {
            updateTodo(state.user.access_token, todo).then(res => {
                if (res){
                    dispatch({ type: "updated", payload: {response: todo, alert: {type: "success", message: "Todo updated."} }})
                } else {
                    alert("Error! unable to update the todo.");
                }
            })
        }
    }
    return (
        <div className="d-flex justify-content-center align-items-center mt-5">
            <div className="col-xs-12 col-md-6" >
                <h2 className="h4 strong">{state.view === "add" ? "Add todo" : "Edit todo"}</h2>
                <Form>
                    <Form.Group controlId="todoForm.description">
                        <Form.Label>Description:</Form.Label>
                        <Form.Control type="text" placeholder="Enter description" onChange={(e) => setTodo({ ...todo, description: e.target.value })} ref={descriptionRef} value={todo.description} />
                    </Form.Group>
                    <Form.Group controlId="todoForm.date">
                        <Form.Label>Due date</Form.Label>
                        <Form.Control type="date" onChange={(e) => setTodo({ ...todo, due_date: e.target.value })} value={todo.due_date} />
                    </Form.Group>
                    <AutoCompleteTag controlId="todoForm.tags"
                    onInputChange={(t,e) => setTodo({ ...todo, tags: e.target.value})} defaultInputValue={todo.tags}  options={tags} />
                    <Form.Group controlId="todoForm.priority">
                        <Form.Label>Priority:</Form.Label>
                        <Form.Control as="select" onChange={(e) => setTodo({ ...todo, priority: e.target.value })} value={todo.priority}>
                            <option value={1} defaultValue>Low</option>
                            <option value={2}>Medium</option>
                            <option value={3}>High</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Button variant="primary" type="button" className="col-xs-6 col-md-6" onClick={handleSave} style={{ height: 65 }}>
                            {state.view === "add" ? "Create" : "Update"}
                        </Button>
                        <Button variant="secondary" type="button" className="col-xs-6 col-md-6" onClick={() => dispatch({type:"cancel"})} style={{ height: 65 }}>
                            Cancel
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </div>
    );
}