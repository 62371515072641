import { postFetchCall, makeFetchCall} from './BaseService';
import config from '../config';

export const fetchRecurringTodos= async (accessToken, filter) => {
    const url = config.Todo.API_HOST + "/recurring_todos";
    const response = await makeFetchCall(url, accessToken);
    const responseJson = await response.json();
    console.log("Fetched entrys.");
    return responseJson;
}
export const updateRecurringTodo = async (accessToken, data) => {
    console.log("Inside updateRecurringTodo function.");
    const url = config.Todo.API_HOST + "/recurring_todos";
    const response = await postFetchCall(url, "PUT", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}

export const deleteRecurringTodo = async (accessToken, data) => {
    console.log("Inside updateRecurringTodo function.");
    const url = config.Todo.API_HOST + "/recurring_todos";
    const response = await postFetchCall(url, "DELETE", accessToken, data);
    const responseJson = await response.json();
    return responseJson;
}
