import React, { useEffect, useState } from 'react';
import { useRoutes} from 'hookrouter';
import { useSSO, MySpinner } from 'appshome-react';
import Home from './screens/Home';
import { Layout } from './screens/Layout';
import routes from './router';
import config from './config';
import { NotFound } from './screens/NotFound';
import { TodoContextProvider } from './context/TodoContext';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function App() {
  const { isAuthenticated, isAuthenticating, authCode } = useSSO(config.SESSION_JS_URL, config.Todo.CLIENT_ID);
  const routeResult = useRoutes(routes);
  const [user, setUser] = useState(null);
  const [authCodeProcessed, setAuthCodeProcessed] = useState(false);
  useEffect(() => processAuthCode(isAuthenticated, authCode, setUser, setAuthCodeProcessed), [isAuthenticated, authCode]);
  if (isAuthenticating || (!authCodeProcessed && isAuthenticated)) {
    return (<MySpinner />);
  } else {
    if (!isAuthenticated ) {
      return (<Home />);
    }
    return (
      <TodoContextProvider user={user}>
        <Layout isAuthenticated={isAuthenticated}>
          {routeResult || <NotFound />}
        </Layout>
      </TodoContextProvider>
    )
  }
}

export default App;
function processAuthCode(isAuthenticated, authCode, setUser, setAuthCodeProcessed) {
  console.log("Inside processAuthCode");
  if (isAuthenticated) {
    fetch(config.Todo.API_HOST + "/process_auth_code/" + authCode, { mode: 'cors' })
      .then(res => res.json())
      .then(res => {
        console.log("Processed authCode.");
        setUser(res);
        setAuthCodeProcessed(true);
      })
      .catch(error => {
        //setUser({"access_token": "123"});//todo: remove once golang server implements the above method
        setAuthCodeProcessed(true);
        console.log("Error while processing authCode. ", error)
      });
  }
}