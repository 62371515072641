import React, { useEffect, useState } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import { useInputFocus } from 'appshome-react';
import { useRecurringTodoAPI } from '../hooks/UseRecurringTodoAPI';
import { AutoCompleteTag } from './TodoFormComponents';

export default function EditRecurringTodoForm(props) {
    const [state, dispatch, service] = useRecurringTodoAPI();
    const selectedTodo = state.selectedRecurringTodo;
    const [todo, setTodo] = useState(selectedTodo);
    const descriptionRef = useInputFocus();
    const tags = Object.keys(state.tags).map((tag, i) => tag);
    const loadTags = service.loadTags;
    useEffect(() => {loadTags()}, [loadTags]);
    return (
        <div className="d-flex justify-content-center align-items-center mt-5 ">
            <Form className="w-100">
                <Form.Row>
                    <h2 className="h4 strong">Edit recurring todo</h2>
                </Form.Row>
                <Form.Row>
                    <Form.Group controlId="todoForm.description" as={Col} xs={12}>
                        <Form.Label>Description:</Form.Label>
                        <Form.Control type="text" placeholder="Enter description" onChange={(e) => setTodo({ ...todo, description: e.target.value })} value={todo.description} ref={descriptionRef} />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group controlId="todoForm.repeatOptions" as={Col} xs={12} md={2}>
                        <Form.Label>Repeats:</Form.Label>
                        <Form.Control as="select" onChange={(e) => setTodo({ ...todo, repeat_options: e.target.value })}>
                            <option value="Daily">Daily</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                        </Form.Control>
                    </Form.Group>
                    <Col xs={12} md={2}>
                        <Form.Group controlId="todoForm.day">
                            <Form.Label>Day:</Form.Label>
                            <Form.Control type="number" min={0} max={31} onChange={(e) => setTodo({ ...todo, day: e.target.value })} value={todo.day} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={2}>
                        <Form.Group controlId="todoForm.month">
                            <Form.Label>Month:</Form.Label>
                            <Form.Control type="number" min={0} max={12} onChange={(e) => setTodo({ ...todo, month: e.target.value })} value={todo.month} />
                        </Form.Group>
                    </Col>
                    <AutoCompleteTag controlId="todoForm.tags" as={Col} xs={12} md={4}
                    onInputChange={(t, e) => setTodo({ ...todo, tags: e.target.value })} options={tags} defaultInputValue={todo.tags} />
                    <Form.Group controlId="todoForm.priority" as={Col} xs={12} md={2}>
                        <Form.Label>Priority:</Form.Label>
                        <Form.Control as="select" onChange={(e) => setTodo({ ...todo, priority: e.target.value })} value={todo.priority}>
                            <option value={1} defaultValue>Low</option>
                            <option value={2}>Medium</option>
                            <option value={3}>High</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} className="text-center">
                        <Button variant="primary" type="button" className="col-xs-6 col-md-6" onClick={() => service.handleUpdate(todo)} style={{ height: 65 }}>
                            Update
                        </Button>
                    </Form.Group>
                    <Form.Group as={Col} className="text-center">
                        <Button variant="secondary" type="button" className="col-xs-6 col-md-6" onClick={() => dispatch({ type: "cancel" })} style={{ height: 65 }}>
                            Cancel
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        </div>
    );
}