import React, { useContext, useState} from 'react';
import {Header} from '../components/Header';
import {Sidebar} from '../components/Sidebar';
import { TodoContext } from '../context/TodoContext';

export function Layout(props) {
    const [state,,] = useContext(TodoContext);
    const mediaQuery = "(min-width: 992px)";
    const [showSidebar, toggleSidebar] = useState(false);
    const media = window.matchMedia(mediaQuery);
    const [isLGBreakPoint, setIsLGBreakPoint] = useState(media.matches);
    //console.log("Initial value of isLGBreakPoint: " + isLGBreakPoint);
    const handleMediaChange = () => {
        setIsLGBreakPoint(media.matches);
        //console.log("Value of isLGBreakPoint: " + isLGBreakPoint);
    };
    media.addListener(handleMediaChange);
    return (
        <div className="h-100 flex">
            <Header isAuthenticated={props.isAuthenticated} toggleSidebar={toggleSidebar} showSidebar={showSidebar} user={state.user.user_email.charAt(0).toUpperCase()} />
            <div className="row h-100 flex flex-row">
                {(isLGBreakPoint || showSidebar) && 
                    <div className="col-xs-12 col-lg-2">
                        <Sidebar  toggleSidebar={toggleSidebar} showSidebar={showSidebar} isLGBreakPoint={isLGBreakPoint} isAuthenticated={props.isAuthenticated} />
                    </div>
                }
                {(isLGBreakPoint || (!isLGBreakPoint && !showSidebar)) && 
                    <div className="col-lg-10 col-xs-12">
                        {props.children}
                    </div>
                }
            </div>
        </div>
    );
}