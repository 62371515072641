import { navigate } from 'hookrouter';
import React, { useEffect } from 'react';
import { Row, Col, Container, Badge } from 'react-bootstrap';
import { useTodoAPI } from '../hooks/UseTodoAPI';

export default function Dashboard(props) {
    const [state,, service] = useTodoAPI();
    const loadStats = service.loadStats;
    const loadTags = service.loadTags
    useEffect(() => {loadStats()}, [loadStats]);
    useEffect(() => {loadTags()}, [loadTags]);
    let statsData = state.stats;
    const getStatsValue = (key) => {
        if (statsData[key] !== undefined) {
            return parseFloat(statsData[key]).toFixed(2);
        }
        return null;
    }
    return (
        <Container>
            <div className="row">
                <h2>Stats</h2>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-4" >
                    <h3>Over all</h3>
                    <ul className="list-group">
                        <li className="list-group-item"><StatsItem caption="Total:" badge={statsData['Total']} /></li>
                        <li className="list-group-item"><StatsItem caption="Incomplete:" badge={statsData['incomplete']} /></li>
                        <li className="list-group-item"><StatsItem caption="Complete:" badge={statsData['complete']} /></li>
                    </ul>
                </div>
                <div className="col-sm-12 col-md-4" >
                    <h3>Completion</h3>
                    <ul className="list-group">
                        <li className="list-group-item"><StatsItem caption="Today:" badge={getStatsValue('total_completed_today')} /></li>
                        <li className="list-group-item"><StatsItem caption="Daily Average:" badge={getStatsValue('avg_daily_completed')} /></li>
                        <li className="list-group-item"><StatsItem caption="Weekly Average:" badge={getStatsValue('avg_weekly_completed')} /></li>
                        <li className="list-group-item"><StatsItem caption="Monthly Average:" badge={getStatsValue('avg_monthly_completed')} /></li>
                    </ul>
                </div>
                <div className="col-sm-12 col-md-4" >
                    <h3>Creation</h3>
                    <ul className="list-group">
                        <li className="list-group-item"><StatsItem caption="Today:" badge={getStatsValue('created_today')} /></li>
                        <li className="list-group-item"><StatsItem caption="Daily Average:" badge={getStatsValue('avg_daily_created')} /></li>
                        <li className="list-group-item"><StatsItem caption="Weekly Average:" badge={getStatsValue('avg_weekly_created')} /></li>
                        <li className="list-group-item"><StatsItem caption="Monthly Average:" badge={getStatsValue('avg_monthly_created')} /></li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12" >
                    <h2>Tags overview</h2>
                    <ul className="list-group">
                        {Object.keys(state.tags).map((tag, i) => {
                        return (<li className="list-group-item"><StatsItem caption={tag} badge={state.tags[tag]} /></li>)
                        })}
                    </ul>
                </div>
            </div>
        </Container>
    )
}
function StatsItem(props) {
    return (
        <Row onClick={()=> navigate("/search", false,  {tag: props.caption})}>
            <Col xs={10}>{props.caption}</Col>
            <Col xs={2}><Badge pill variant="info">{props.badge}</Badge></Col>
        </Row>
    )
}