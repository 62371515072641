import React from 'react';
import { Navbar, Button, Dropdown } from 'react-bootstrap';
import config from '../config';

export function Header(props) {
    const host = window.location.protocol + "//" + window.location.host;
    return (
        <Navbar expand="lg" bg="dark" variant="dark" collapseOnSelect="true"   className="justify-content-between">
        <Navbar.Text className="mr-2">
            <Button   title="menu" className="navbar-toggler-icon d-inline d-lg-none navbar-toggler" size="sm" variant="outline-default"  onClick={() => props.toggleSidebar(!props.showSidebar)}>
                {/* <span className="navbar-toggler-icon"></span> */}
            </Button>
            </Navbar.Text>
            <Navbar.Brand className="mr-auto h1" href="/">Todo</Navbar.Brand>
            <Dropdown >
            {(props.isAuthenticated) && <Dropdown.Toggle variant="info" id="dropdown-basic" >
                    {props.user}
                </Dropdown.Toggle>}
                <Dropdown.Menu>
                <Dropdown.Item href="#">About</Dropdown.Item>
                <Dropdown.Item href="#">Profile</Dropdown.Item>
                <Dropdown.Item href={config.AUTH_LOGOUT_URL + "?redirect_url=" + host} >Sign out</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {/* {!props.showSidebar && <Navbar.Toggle aria-controls="basic-navbar-nav" />}
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Item><A href="/about" className="nav-link">About</A></Nav.Item>
                    <AuthOptions isAuthenticated={props.isAuthenticated} />
                </Nav>
            </Navbar.Collapse> */}
        </Navbar>
    )
}