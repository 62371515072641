import React, { useEffect, useContext, useState } from 'react';
import { Modal, Alert, ButtonToolbar, NavLink, Dropdown, Navbar, Row, Col, ListGroup, ButtonGroup, Button, Form, Container } from 'react-bootstrap';
import { Spinner } from '../components/Spinner';
import useInputFocus from 'appshome-react/dist/useInputFocus';
import MdSearch from 'react-ionicons/lib/MdSearch';
import MdAdd from 'react-ionicons/lib/MdAdd';
import Bulk from 'react-ionicons/lib/MdAddCircle';
import MdCheckmark from 'react-ionicons/lib/MdCheckmark';
import MdCreate from 'react-ionicons/lib/MdCreate';
import MdArrowDropleft from 'react-ionicons/lib/MdArrowDropleft';
import MdArrowDropright from 'react-ionicons/lib/MdArrowDropright';
import MdArrowDown from 'react-ionicons/lib/MdArrowDown';
import MdArrowUp from 'react-ionicons/lib/MdArrowUp';
import MdCalendar from 'react-ionicons/lib/MdCalendar';
import MdOptions from 'react-ionicons/lib/MdOptions';
import { useTodoAPI } from '../hooks/UseTodoAPI'
import { TodoContext } from '../context/TodoContext';
import TodoForm from '../components/TodoForm';
import BulkTodoForm from '../components/BulkTodoForm';
import { AutoCompleteTag } from '../components/TodoFormComponents';
import Linkify from 'react-linkify';
import { useQueryParams } from 'hookrouter';

export default function ListTodos(props) {
    return (
        <TodoPanel mode={props.mode} />
    );
}
function TodoPanel(props) {
    const [state, dispatch] = useContext(TodoContext)
    const [queryParams] = useQueryParams();
    const tagQuery = queryParams.tag;

    useEffect(() => {
        if ("search" === props.mode) {
        dispatch({ type: "searchResult", payload: { tag: tagQuery, status: "incomplete" } })
        } else {
            dispatch({ type: "today", payload: {} })
        }
    }, [props.mode])
    return (
        <Spinner isLoading={state.isLoading}>
            <Container fluid className="h-100">
                <TodoBar />
                {state.view === "list" && <Todos />}
                {state.view === "search" && <SearchTags />}
                {["add", "edit"].includes(state.view) && <TodoForm />}
                {state.view === "bulkAdd" && <BulkTodoForm />}
            </Container>
        </Spinner>
    )
}
function Todos(props) {
    const [state,] = useContext(TodoContext);
    return (
        [<AlertMessage />,
        <TodoTitleBar />,
        <ListGroup as="ul" key={1}>
            {state.list.slice(0, state.displayedPages * state.pageSize).map((todo, i) => <Todo todo={todo} todoKey={i} />)}
        </ListGroup>,
        <ChangeDateModal key={2} />,
        <Pagination />]
    )
}
function TodoTitleBar(props) {
    const [state, dispatch] = useContext(TodoContext)
    return (
        <div className="justify-content-between navbar navbar-light">
            {state.calendar && <span className="navbar-text"
                onClick={() => dispatch({ type: "decreamentDate" })}>
                <MdArrowDropleft />
                <span className="sr-only">Previous day</span>
            </span>}
            <h2 className="navbar-text h4">{state.heading}</h2>
            {state.calendar && <span className="navbar-text" onClick={() => dispatch({ type: "increamentDate" })}><MdArrowDropright /><span className="sr-only">Next day</span></span>}
        </div>
    )
}
function Todo(props) {
    const todo = props.todo;
    const [state, dispatch] = useContext(TodoContext)
    return (
        <ListGroup.Item
            as="li"
            key={props.todoKey}
            action
            onClick={() => dispatch({ type: "toggleSelect", payload: todo.id })}
            variant={state.selectedTodos.includes(todo.id) ? "dark" : "light"}>
            <Row className="bg-transparent">
                <Col xs={12} md={3} className="bg-transparent">
                    <span className="sr-only">{state.selectedTodos.includes(todo.id) ? "Selected " : "Not selected "}</span>
                    <strong>{new Date(Date.parse(todo.due_date)).toDateString()}</strong>
                </Col>
                <Col xs={12} md={6} className="bg-transparent">
                    <Linkify properties={{ target: '_blank' }}>
                        {todo.description}
                    </Linkify>
                </Col>
                <Col xs={12} md={3} className="bg-transparent">
                    {"incomplete" === todo.status ?
                        <IncompleteOptions todo={todo} />
                        : <CompleteOptions todo={todo} />}
                </Col>
            </Row>
        </ListGroup.Item>
    );
}
function IncompleteOptions(props) {
    const [, dispatch, service] = useTodoAPI();
    return (
        <ButtonToolbar>
            <ButtonGroup>
                <Button variant="info" onClick={() => dispatch({ type: "edit", payload: props.todo })}>
                    <MdCreate fontSize="24" color="white" />
                    <span className="sr-only">Edit</span>
                </Button>
                <Button variant="info" onClick={() => service.markAsDone(props.todo)}>
                    <MdCheckmark fontSize="24" color="white" />
                    <span className="sr-only">Mark as done</span>
                </Button>
            </ButtonGroup>
        </ButtonToolbar>
    )
}
function CompleteOptions(props) {
    return (
        <ButtonToolbar>
            <ButtonGroup>
                <Button variant="info">Mark as not done</Button>
            </ButtonGroup>
        </ButtonToolbar>
    )
}
function TodoBar(props) {
    const [state, dispatch, service] = useTodoAPI()
    return (
        <Navbar className="justify-content-between" bg="info" variant="light">
            <Navbar.Text role="link" onClick={() => dispatch({ type: "today" })}>
                <MdCalendar fontSize="24" color="white" />
                <span className="sr-only">Calendar view</span>
            </Navbar.Text>
            <Dropdown>
                <Dropdown.Toggle>
                    Status
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => dispatch({ type: "incomplete" })}>Incomplete</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch({ type: "complete" })}>Complete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Navbar.Text role="link" onClick={() => dispatch({ type: "search" })} title="Search">
                <MdSearch fontSize="24" color="white" />
            </Navbar.Text>
            <Navbar.Text role="link" onClick={() => dispatch({ type: "add" })} title="Add">
                <MdAdd fontSize="24" color="white" />
            </Navbar.Text>
            <Navbar.Text role="link" onClick={() => dispatch({ type: "bulkAdd" })} title="Bulk add">
                <Bulk fontSize="24" color="white" />
                <span className="sr-only">Bulk add</span>
            </Navbar.Text>
            <Dropdown className="pull-right">
                <Dropdown.Toggle as={NavLink} >
                    <MdOptions color="white" fontSize="24" />
                    <span className="sr-only">Options</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => dispatch({ type: "selectAll" })}>Select all</Dropdown.Item>
                    {state.selectedTodos.length > 0 && <Dropdown.Item onClick={() => dispatch({ type: "clearSelection" })}>Clear selection</Dropdown.Item>}
                    {state.selectedTodos.length > 0 && <Dropdown.Item onClick={service.markSelectedAsDone}>Mark as done</Dropdown.Item>}
                    {state.selectedTodos.length > 0 && <Dropdown.Item onClick={() => dispatch({ type: "changeDate" })}>Change date</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        </Navbar>
    )
}
function SearchTags(props) {
    const searchRef = useInputFocus();
    const [state, dispatch, service] = useTodoAPI();
    const loadTags = service.loadTags;
    useEffect(() => { loadTags() }, [loadTags]);
    const [filter, setFilter] = useState({ tag: "", status: "All" })
    const tags = Object.keys(state.tags).map((tag, i) => tag);

    return (
        <Container fluid className="d-flex h-100 justify-content-center"  >
            <Form className="w-25">
                <h2 className="h4">Search tags</h2>
                <AutoCompleteTag controlId="searchTag.tag" searchRef={searchRef} onChange={(e) => setFilter({ ...filter, tag: e })} options={tags} />
                <Form.Group controlId="searchTag.status">
                    <Form.Label>Status:</Form.Label>
                    <Form.Control as="select" onChange={(e) => setFilter({ ...filter, status: e.target.value })}>
                        <option value="All" defaultValue>All</option>
                        <option value="incomplete">Incomplete</option>
                        <option value="complete">Complete</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Button variant="info" type="button" onClick={() => dispatch({ type: "searchResult", payload: filter })}>
                        <MdSearch /><span>Go</span>
                    </Button>
                </Form.Group>
            </Form>
        </Container>
    );
}
function ChangeDateModal(props) {
    const [state, dispatch, service] = useTodoAPI();
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
    return (
        <Modal show={state.modal === "changeDate"} backdrop="static" size="sm" centered onHide={() => dispatch({ type: "closeModal" })}>
            <Modal.Header closeButton>
                <Modal.Title>Change date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="changeDateForm.date">
                        <Form.Label>New date:</Form.Label>
                        <Form.Control type="date" onChange={(e) => setDate(e.target.value)} value={date} />
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={() => service.changeDate(date)}>Change</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
export function AlertMessage(props) {
    const [state, dispatch] = useContext(TodoContext)
    if (state.alert) {
        setTimeout(() => dispatch({ type: "closeAlert" }), 10000)
        return (
            <Alert variant={state.alert.type}>
                {state.alert.message}
            </Alert>
        )
    } else {
        return null;
    }
}
function Pagination(props) {
    const [state, dispatch] = useContext(TodoContext)
    if (state.list.length < state.pageSize) {
        return null;
    }
    return (
        <div className="navbar navbar-light justify-content-between">
            <Button size="sm" disabled={state.displayedPages === 1} variant="outline" onClick={() => dispatch({ type: "decreamentPage" })}>
                <MdArrowUp />
                <span className="sr-only">Load less</span>
            </Button>
            <Button size="sm" disabled={state.list.length < (state.pageSize * state.displayedPages)} variant="outline" onClick={() => dispatch({ type: "increamentPage" })}>
                <MdArrowDown />
                <span className="sr-only">Load more</span>
            </Button>
        </div>
    )
}