import React, {createContext} from 'react';
import * as actions from '../actions/Backlog';
import { useAsyncReducer } from '../hooks/UseAsyncReducer';

export const BacklogContext = createContext();

const initialState = {
    user: null,
    isLoading: false,
    view: "list",
    list: [],
    selectedBacklog: null,
    alert: null
}

const reducer = async(state, action) => actionMap[action.type](state, action)

export function BacklogContextProvider(props) {
    const initState = {...initialState, user: props.user}
    const [state, dispatch] = useAsyncReducer(reducer, initState)
return (
    <BacklogContext.Provider value={[state, dispatch]}>
        {props.children}
    </BacklogContext.Provider>
)
}

const actionMap = {
    load: actions.load,
    add: actions.add,
    saved: actions.saved,
    edit: actions.edit,
    update: actions.update,
    delete: actions.remove,
    cancel: actions.cancel,
    closeAlert: actions.closeAlert,
}