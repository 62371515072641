import React from 'react';
import Dashboard from './screens/Dashboard';
import ListTodos from './screens/ListTodos';
import { RecurringTodos } from './screens/RecurringTodos';
import { Backlog } from './screens/Backlog';

const routes = {
    "/": () => <Dashboard  />,
    "/dashboard": () => <Dashboard  />,
    "/list": () => <ListTodos mode="list" />,
    "/search": () => <ListTodos mode="search" />,
    "/recurring-todos": () => <RecurringTodos />,
    "/backlog": () => <Backlog />,
}

export default routes;