import { saveBacklog, fetchBacklog, updateBacklog, deleteBacklog  } from "../services/BacklogService"

export async function add(state, action) {
    return {...state, view: "add"}
}
export async function saved(state, action) {
    const backlog = action.payload.backlog;
    const id = await saveBacklog(state.user.access_token, backlog);
    backlog['id'] = id;
    return {...state, list: [...state.list, backlog], view: "list", alert: {type: "success", message: "Backlog created."}}
}
export async function load(state, action) {
    const backlog = await fetchBacklog(state.user.access_token);
    return {...state, list: backlog}
}
export async function edit(state, action) {
    return {...state, view: "edit", selectedBacklog: action.payload.selectedBacklog}
}
export async function cancel(state, action) {
    return { ...state, view: "list"}
}

export async function update(state, action) {
    await updateBacklog(state.user.access_token, action.payload.backlog)
    return { ...state, alert: {type: "success", message: "Backlog updated."}, view: "list"};
}
export async function closeAlert(state, action) {
    return { ...state, alert: null};
}
export async function remove(state, action) {
    await deleteBacklog(state.user.access_token, action.payload.backlog)
    return { ...state, alert: {type: "success", message: "Backlog deleted."}, list: state.list.filter((b, i) => b.id !== action.payload.backlog.id), view: "list"}
}
