import  { useCallback, useContext } from 'react';
import { TodoContext } from '../context/TodoContext';
import { updateBulkTodos, fetchStats, fetchTags, updateTodo, addBulkTodos } from '../services/TodoService';

export function useTodoAPI() {
    const [state, dispatch] = useContext(TodoContext)
    const markAsDone = useCallback(async(todo) => {
        todo.status = "complete"
        todo.completed_at = new Date().toISOString();
        updateTodo(state.user.access_token, todo).then(res => {
            if (res) {
                dispatch({type: "updated", payload: {response: todo, alert: {type: "success", message: "Selected todo marked as done."}}})
            }
        })
    }, [state.user.access_token, dispatch])

    const loadStats = useCallback(async() => {
        fetchStats(state.user.access_token)
        .then(res => dispatch({type: "setStats", payload: res}))
    },[dispatch, state.user.access_token])

    const loadTags = useCallback(async() => {
        fetchTags(state.user.access_token)
        .then(res => dispatch({type: "setTags", payload: res}))
    }, [dispatch, state.user.access_token]);

    const changeDate = (newDate) => {
        if (newDate === null || newDate === "") {
            alert("Invalid date!")
            return;
        }
        const selectedTodos = state.list.filter((todo) => state.selectedTodos.includes(todo.id))
        selectedTodos.forEach((t) => t.due_date = newDate);
        bulkUpdateInternal(selectedTodos, "Due date updated for the selected todos.");
    }
    const bulkUpdateInternal = (todos, successMessage) => {
        updateBulkTodos(state.user.access_token, {items: todos})
        .then(res => {
            if ("success" === res) {
                dispatch({type: "updated", payload: {alert: {type: "success", message: successMessage}}})
            }
        }).catch(e => alert(e))
    }
    const markSelectedAsDone = () => {
        const selectedTodos = state.list.filter((todo) => state.selectedTodos.includes(todo.id))
        selectedTodos.forEach(t => t.status = "complete")
        bulkUpdateInternal(selectedTodos, "Selected items updated.")
    }

    const addBulk = useCallback(async(todos) => {
        addBulkTodos(state.user.access_token, {items: todos}).then(res => {
            todos.forEach((t, i) => t['id'] = res[i])
            dispatch({type: "bulkSaved", payload: {todos: todos, alert: {type: "success", message: "New todos created."}}})
        })
    },[dispatch, state.user.access_token]);

    return [
        state, 
        dispatch, 
        {
            markAsDone: markAsDone,
            loadStats: loadStats,
            loadTags: loadTags,
            changeDate: changeDate,
            markSelectedAsDone: markSelectedAsDone,
            addBulk: addBulk,
        }
    ];
}