import React from 'react';
import { MySpinner } from 'appshome-react';

export function Spinner(props) {
    if (props.isLoading) {
        return (
            <MySpinner/>
        )
    } else {
        return props.children
    }
}